<template>
    <div>
        <v-card :loading="loading">
            <v-card-title>
                <v-btn small icon @click="close" color="primary" class="mr-2">
                    <v-icon>
                        mdi-close
                    </v-icon>
                </v-btn>
                <h3>New Item</h3>
            </v-card-title>
            <v-divider class="ml-4 mr-5" />
            <v-card-text class="mt-n5">
                <v-form v-model="valid">
                    <v-row class="mt-2">
                        <v-col cols="12">
                            <v-text-field
                                v-model="item.description"
                                label="Description"
                                prepend-icon="mdi-book-open-outline"
                                required
                                :rules="[rules.required]"
                            >
                            </v-text-field>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-spacer />
                <v-btn
                    color="primary"
                    class="mr-2 mb-3"
                    rounded
                    :disabled="!valid"
                    @click="save"
                    :loading="loading"
                >
                    REQUEST
                </v-btn>
            </v-card-actions>
        </v-card>
    </div>
</template>

<script>
import API from '@/services/api'
import { mapMutations } from 'vuex'

export default {
    name: 'NewItem',
    data: () => ({
        valid: false,
        loading: false,
        item: {
            description: null,
        },
        rules: {
            required: v => !!v || 'The value is required!',
        },
    }),
    methods: {
        ...mapMutations(['setErrorItems']),
        async save() {
            try {
                this.loading = true
                await API.addRequest(this.item)
                this.$emit('save')
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        close() {
            this.$emit('closeDialog')
        },
    },
}
</script>

<style></style>
