<template>
    <div>
        <v-data-table
            :headers="headers"
            :items="filterItems"
            class="elevation-0"
            :mobile-breakpoint="0"
            :loading="loading"
            disable-pagination
            hide-default-footer
        >
            <template v-slot:top>
                <v-row
                    no-gutter
                    class="mt-0 mx-0 pt-4 pb-0"
                    style="background-color: #eeeeee"
                >
                    <v-col cols="12" class="d-flex">
                        <v-combobox
                            :items="codes"
                            rounded
                            outlined
                            hide-details
                            dense
                            prepend-inner-icon="mdi-magnify"
                            append-icon=""
                            ref="numberComboBox"
                            v-model="search"
                            placeholder="Search"
                            item-text="description"
                            :search-input.sync="searchText"
                            @change="searchItem"
                        >
                            <template v-slot:append>
                                <v-btn
                                    v-if="reset"
                                    icon
                                    color="red"
                                    small
                                    @click="resetSearch"
                                >
                                    <v-icon>
                                        mdi-close
                                    </v-icon>
                                </v-btn>
                            </template>
                        </v-combobox>
                        <v-spacer />
                        <v-btn
                            rounded
                            color="primary"
                            @click="openDialogNewItem"
                            class="mx-2"
                        >
                            <v-icon>
                                mdi-plus
                            </v-icon>
                            {{ $vuetify.breakpoint.smAndDown ? '' : 'NEW' }}
                        </v-btn>
                        <v-btn
                            v-if="uploadStock"
                            rounded
                            color="primary"
                            @click="openDialogToUploadFile"
                            class="mx-2"
                        >
                            <v-icon>
                                mdi-arrow-up
                            </v-icon>
                            UPLOAD
                        </v-btn>
                    </v-col>
                </v-row>
            </template>
            <!--ITEMS-->
            <template v-slot:[`item.actions`]="{ item }">
                <div class="d-flex justify-center">
                    <v-btn small icon @click.stop="openDialogReserve(item)">
                        <v-icon>mdi-database-edit-outline</v-icon>
                    </v-btn>
                </div>
            </template>
        </v-data-table>
        <!--New Item-->
        <v-dialog
            :retain-focus="false"
            v-model="openNewItem"
            persistent
            :width="$vuetify.breakpoint.mobile ? '80%' : '40%'"
        >
            <NewItem
                v-if="openNewItem"
                @closeDialog="closeDialogNewItem"
                @save="save"
            />
        </v-dialog>
        <!--Item Reserve-->
        <v-dialog
            :retain-focus="false"
            v-model="openReserve"
            persistent
            fullscreen
        >
            <ItemReserve
                v-if="openReserve"
                :item="selectedItem"
                :projectId="projectId"
                @closeDialog="closeDialogReserve"
                @reserve="reserve"
            />
        </v-dialog>
        <!--Upload File-->
        <v-dialog
            :retain-focus="false"
            v-model="openUploadFile"
            persistent
            :width="$vuetify.breakpoint.mobile ? '80%' : '40%'"
        >
            <v-card :loading="loading2" v-if="openUploadFile">
                <v-card-title>
                    <v-btn
                        small
                        icon
                        @click="closeDialogToUploadFile"
                        color="primary"
                        class="mr-2"
                    >
                        <v-icon>
                            mdi-close
                        </v-icon>
                    </v-btn>
                    <h3>Upload File</h3>
                </v-card-title>
                <v-divider class="ml-4 mr-5" />
                <v-card-text class="mt-4">
                    <v-form v-model="valid">
                        <v-row>
                            <v-col cols="12">
                                <v-file-input
                                    v-model="file"
                                    label="Stock"
                                    hide-details
                                    accept=".xlsx"
                                >
                                </v-file-input>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12">
                                <v-file-input
                                    v-model="codesFile"
                                    label="Codes"
                                    hide-details
                                    accept=".xlsx"
                                >
                                </v-file-input>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer />
                    <v-btn
                        color="primary"
                        class="mr-2 mb-3"
                        rounded
                        :disabled="!file && !codesFile"
                        @click="uploadFile"
                        :loading="loading2"
                    >
                        UPLOAD
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-alert
            v-if="activateAlert"
            type="success"
            color="primary"
            style="position: absolute; right: 0px; bottom: 0px"
        >
            <v-row no-gutters>
                <v-col cols="11">
                    <p class="my-0">{{ alertMessage }}</p>
                </v-col>
                <v-col cols="1">
                    <v-btn
                        small
                        icon
                        class="mt-n1"
                        @click="activateAlert = false"
                    >
                        <v-icon>
                            mdi-close
                        </v-icon>
                    </v-btn>
                </v-col>
            </v-row>
        </v-alert>
    </div>
</template>

<script>
import ItemReserve from '@/components/StockRoom/ItemReserve.vue'
import NewItem from '@/components/StockRoom/NewItem.vue'
import _ from 'lodash'
import API from '@/services/api'
import { mapMutations } from 'vuex'
import * as XLSX from 'xlsx/xlsx.mjs'
import { storage } from '@/services/firebase'

export default {
    name: 'Stock',
    props: {
        type: String,
        projectId: {
            type: String,
            default: () => undefined,
        },
    },
    components: {
        ItemReserve,
        NewItem,
    },
    data: () => ({
        codesFile: undefined,
        valid: false,
        loading: false,
        openNewItem: false,
        openPurchase: false,
        openReserve: false,
        selectedItem: {},
        headers: [
            {
                text: 'CODE',
                value: 'code',
                align: 'center',
                sortable: false,
            },
            {
                text: 'DESCRIPTION',
                value: 'description',
                align: 'center',
                sortable: false,
            },
            {
                text: 'AVAILABLE',
                value: 'available',
                align: 'center',
                sortable: false,
            },
            {
                text: 'RESERVED',
                value: 'reserved',
                align: 'center',
                sortable: false,
            },
            {
                text: 'PRICE (UNIT)',
                value: 'price',
                align: 'center',
                sortable: false,
            },
            {
                text: 'TOTAL PRICE',
                value: 'total',
                align: 'center',
                sortable: false,
            },
            {
                text: 'ACTIONS',
                value: 'actions',
                align: 'center',
                sortable: false,
            },
        ],
        items: [],
        search: '',
        searchText: '',
        uploadStock: null,
        activateAlert: false,
        file: undefined,
        openUploadFile: false,
        rules: {
            required: v => !!v || 'The file is required',
        },
        tempItems: [],
        fileHeaders: {
            Material: 'material',
            'Texto breve de material': 'short_material_text',
            'Alm.': 'storage',
            'Nombre de proyecto': 'project_name',
            Orden: 'order',
            ' Libre utilización': 'free_use',
            ' Valor libre util.': 'usable_free_value',
        },
        companyId: JSON.parse(localStorage.getItem('company')),
        loading2: false,
        codes: [],
        selectedItems: [],
        reset: false,
        downloadFiles: false,
        storeMNP: 1158,
        sapCodeMNP: 'MNP-001',
        mockupProjectName: 'Mockup No Proyetos',
        storeNC: 1157,
        storeLouvers: 1159,
        sapCodeLouvers: 'LVR-001',
        LouversProjectName: 'Stock Louvers',
    }),
    async created() {
        const {
            data: { user },
        } = await API.getMyInfo()
        this.uploadStock = user.permissions.find(
            permission => permission == 'uploadStock'
        )
    },
    async mounted() {
        try {
            this.loading = true
            await this.getStock()
            await this.getStockCodes()
        } catch (error) {
            this.setErrorItems({
                source: this.$options.name,
                message: error.message,
            })
        } finally {
            this.loading = false
        }
    },
    watch: {
        async downloadFiles() {
            if (this.downloadFiles) {
                this.loading = true
                await this.getStock()
                await this.getStockCodes()
                this.downloadFiles = false
                this.loading = false
            }
        },
    },
    computed: {
        filterItems() {
            const conditions = []
            if (this.selectedItems.length > 0) {
                return this.selectedItems
            } else {
                if (this.searchText) {
                    conditions.push(this.filterData)
                }
                if (conditions.length > 0) {
                    return this.items.filter(item => {
                        return conditions.every(condition => {
                            return condition(item)
                        })
                    })
                }
                return this.items
            }
        },
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        resetSearch() {
            this.selectedItems = []
            this.search = ''
            this.reset = false
        },
        async searchItem() {
            try {
                this.selectedItems = []
                this.loading = true
                let selectedItem = this.items.find(
                    item => item.code == this.search.material
                )
                if (selectedItem) {
                    this.selectedItems = [selectedItem]
                    if (Number(selectedItem.available) > 0) {
                        this.openDialogReserve(selectedItem)
                    }
                } else if (this.search == '') {
                    this.selectedItems = [
                        {
                            code: this.search.material,
                            description: this.search.short_material_text,
                            available: 0,
                            reserved: 0,
                            price: 0,
                            total: 0,
                            items: [this.search],
                        },
                    ]
                }
                this.reset = true
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        async getStockCodes() {
            try {
                this.codes = await API.getStockCodes()
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            }
        },
        async getStock() {
            try {
                this.items = await API.getStock()
                this.items.forEach(item => {
                    item.available = this.numberFormat(item.available)
                    item.reserved = this.numberFormat(item.reserved)
                    item.price = this.numberFormat(item.price)
                    item.total = this.numberFormat(item.total)
                })
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            }
        },
        numberFormat(amount) {
            return new Intl.NumberFormat('de-DE').format(Math.ceil(amount))
        },
        async uploadFile() {
            try {
                this.loading2 = true
                this.tempItems = []
                const storageRef = storage().ref(`${this.companyId}/stock`)
                if (this.file) {
                    let stock = await this.readFile(this.file, true)
                    if (stock) {
                        stock = this.processFiles(stock[0])
                        await storageRef
                            .child('stock.json')
                            .putString(JSON.stringify(stock))
                    }
                }
                if (this.codesFile) {
                    let codes = await this.readFile(this.codesFile)
                    codes = this.processFiles(codes[0])
                    await storageRef
                        .child('codes.json')
                        .putString(JSON.stringify(codes))
                }
                this.loading2 = false
                this.closeDialogToUploadFile()
                this.downloadFiles = true
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            }
        },
        openDialogToUploadFile() {
            this.openUploadFile = true
        },
        closeDialogToUploadFile() {
            this.openUploadFile = false
            this.file = undefined
            this.codesFile = undefined
        },
        save() {
            this.closeDialogNewItem()
            this.activateAlert = true
            this.alertMessage = 'New item was successfully requested'
        },
        reserve() {
            this.closeDialogReserve()
            this.activateAlert = true
            this.alertMessage = 'Stock was successfully reserved'
        },
        openDialogNewItem() {
            this.openNewItem = true
        },
        closeDialogNewItem() {
            this.openNewItem = false
        },
        openDialogPurchase(item) {
            this.openPurchase = true
            this.selectedItem = _.cloneDeep(item)
        },
        closeDialogPurchase() {
            this.openPurchase = false
            this.selectedItem = {}
        },
        openDialogReserve(item) {
            this.loading = true
            this.openReserve = true
            this.selectedItem = _.cloneDeep(item)
        },
        async closeDialogReserve() {
            this.loading = false
            this.openReserve = false
            this.selectedItem = {}
            await this.getStock()
        },
        async readFile(file, checkRequired = false) {
            try {
                this.tempItems = []
                this.loading = true
                const data = await file.arrayBuffer()
                /* data is an ArrayBuffer */
                const workbook = XLSX.read(data)
                var first_sheet_name = workbook.SheetNames[0]
                /* Get worksheet */
                var worksheet = workbook.Sheets[first_sheet_name]

                if (checkRequired) {
                    // get headers
                    const headers = []
                    const range = XLSX.utils.decode_range(worksheet['!ref'])
                    for (let col = range.s.c; col <= range.e.c; col++) {
                        const cellAddress = XLSX.utils.encode_cell({
                            r: range.s.r,
                            c: col,
                        })
                        const cell = worksheet[cellAddress]
                        headers.push(cell ? cell.v : undefined)
                    }

                    // requiredHeaders
                    const requiredColumns = [
                        'Material',
                        'Texto breve de material',
                        'Alm.',
                        'Orden',
                        ' Libre utilización',
                        ' Valor libre util.',
                    ]
                    const missingColumns = requiredColumns.filter(
                        col => !headers.includes(col)
                    )

                    if (missingColumns.length > 0) {
                        throw new Error(
                            `The following columns are missing from the file: ${missingColumns.join(
                                ', '
                            )}`
                        )
                    }
                }

                this.tempItems.push(
                    XLSX.utils.sheet_to_json(worksheet, {
                        raw: true,
                    })
                )
                return this.tempItems
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        processFiles(items) {
            let response = []
            items.forEach(item => {
                if (item['Material']) {
                    if (item['Alm.'] == this.storeMNP) {
                        item['Nombre de proyecto'] = this.mockupProjectName
                        item['Orden'] = this.sapCodeMNP
                    } else if (item['Alm.'] == this.storeLouvers) {
                        item['Nombre de proyecto'] = this.LouversProjectName
                        item['Orden'] = this.sapCodeLouvers
                    }
                    if (item['Alm.'] != this.storeNC) {
                        const newObject = {}
                        Object.keys(this.fileHeaders).map(key => {
                            newObject[this.fileHeaders[key]] =
                                item[key] || item[key.trim()] || ''
                        })
                        response.push(newObject)
                    }
                }
            })
            return response
        },
        filterData(item) {
            return (
                item.code
                    .toString()
                    .toLowerCase()
                    .includes(this.searchText.toLowerCase()) ||
                item.description
                    .toLowerCase()
                    .includes(this.searchText.toLowerCase())
            )
        },
    },
}
</script>

<style>
.v-data-table {
    border: 1px solid #eeeeee;
}
thead {
    background: #eeeeee;
}
</style>
