<template>
    <v-card :loading="loading">
        <v-card-title>
            <v-btn small icon @click="close" color="primary" class="mr-2">
                <v-icon>
                    mdi-close
                </v-icon>
            </v-btn>
            <h3>ADD RESERVE</h3>
        </v-card-title>
        <v-divider class="ml-4 mr-5" />
        <v-card-text class="mt-2">
            <v-form v-model="valid">
                <v-row no-gutter>
                    <v-col cols="12">
                        <v-text-field
                            v-model="item.reserve"
                            type="number"
                            hide-details
                            label="Quantity"
                            prepend-icon="mdi-database-outline"
                            :max="item.free_use"
                            :rules="[rules.number, numberNotValid]"
                        >
                        </v-text-field>
                    </v-col>
                </v-row>
                <v-row no-gutter>
                    <v-col cols="12">
                        <v-text-field
                            v-model="item.notes"
                            hide-details
                            label="Notes"
                            prepend-icon="mdi-text"
                        >
                        </v-text-field>
                    </v-col>
                </v-row>
            </v-form>
        </v-card-text>
        <v-card-actions>
            <v-spacer />
            <v-btn
                color="primary"
                class="mr-2 mb-3"
                rounded
                @click="reserve"
                :disabled="!valid"
                :loading="loading"
            >
                RESERVE
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import { mapMutations } from 'vuex'
export default {
    name: 'AddReserve',
    props: {
        item: {
            type: Object,
            required: true,
            default: () => {},
        },
    },
    data: () => ({
        loading: false,
        valid: false,
        rules: {
            number: v => v > 0 || 'The value must be greater than 0',
        },
    }),
    methods: {
        ...mapMutations(['setErrorItems']),
        numberNotValid(value) {
            const free_use = Number(
                typeof this.item.free_use == 'string'
                    ? this.item.free_use.replace(/\./gi, '')
                    : this.item.free_use
            )
            return value <= free_use || 'The value is not valid'
        },
        close() {
            this.$emit('closeDialog')
        },
        async reserve() {
            try {
                this.loading = true
                if (
                    Number(
                        typeof this.item.free_use == 'string'
                            ? this.item.free_use.replace(/\./gi, '')
                            : this.item.free_use
                    ) < Number(this.item.reserve)
                ) {
                    throw new Error('The quantity is not correct.')
                }
                await this.$emit('reserve')
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
    },
}
</script>

<style></style>
